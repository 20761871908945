import React from 'react'
import styled from 'styled-components/macro'

import FooterContent from './FooterContent'
import Container from '../../atoms/Container'

export default function LoggedInFooter() {
  return (
    <SFooter>
      <Container big inline alignTop>
        <FooterContent storeLinksInline />
      </Container>
    </SFooter>
  )
}

const SFooter = styled.footer`
  position: relative;
  display: flex;
  width: 100%;
  grid-area: footer;
  background-color: #2c2c2c;
  padding-top: 24px;
  padding-bottom: 16px;
`
