import React from 'react'
import styled, { css } from 'styled-components/macro'

import { Title } from '../../atoms/Typography'
import IOS from '../../../assets/icons/iOS'
import Android from '../../../assets/icons/Android'

export default function Footer({ storeLinksInline }) {
  return (
    <>
      <Contact>
        <Logo>smart gym</Logo>
        <p>P.IVA: 03760251201</p>
      </Contact>
      <Contact>
        <Title as="h5">Sede legale</Title>
        <p>
          Via della luna 1
          <br />
          40128
          <br />
          Bologna (BO)
        </p>
      </Contact>
      <Contact>
        <Title as="h5">Contatti</Title>
        <p>
          M: info@smartgym-app.it <br />
          T: (+39) 333 932 9935
        </p>
      </Contact>
      <Contact>
        <Title as="h5">Scarica l'App</Title>
        <Links storeLinksInline={storeLinksInline}>
          <li>
            <IOS />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/id1534700934"
            >
              iOS
            </a>
          </li>
          <li>
            <Android />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.smartgym.smartgym"
            >
              Android
            </a>
          </li>
        </Links>
      </Contact>
    </>
  )
}

const Contact = styled.section`
  color: #fff;
  font-size: 12px;
  line-height: 22px;

  h5 {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    margin-right: 24px;
  }
  @media screen and (max-width: 1023px) {
    margin-bottom: 24px;
    :first-of-type,
    :last-of-type {
      display: flex;
    }
    :last-of-type {
      margin-bottom: 0;
      h5 {
        margin-right: 0;
      }
    }
    :nth-of-type(2),
    :nth-of-type(3) {
      display: inline-block;
      width: 49%;
      vertical-align: top;
    }
  }
  @media screen and (min-width: 1024px) {
    h5 {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
`

const Links = styled.ul`
  list-style: none;

  ${(props) =>
    props.storeLinksInline &&
    css`
      display: flex;
      align-items: center;

      @media screen and (min-width: 1024px) {
        > li:first-child {
          margin-right: 24px;
        }
      }
    `}

  > li {
    display: flex;
    align-items: center;

    > svg {
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 1023px) {
    display: flex;
    align-items: center;
    > li {
      margin-left: 40px;
    }
  }
`

const Logo = styled.h5`
  font-family: 'AmpleAlt', Arial, Helvetica, sans-serif;
  font-size: 20px !important;
  line-height: 22px;
`
