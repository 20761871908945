import React from 'react'
import styled from 'styled-components'

export default function Hamburger({ onClick }) {
  return (
    <Icon onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </Icon>
  )
}

const Icon = styled.div`
  display: block;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  span {
    display: block;
    width: 16px;
    border-top: 2px solid #452562;
    margin: 6px 0 6px auto;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
`
