import React from 'react'
import { useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'

import { getToken } from '../store/app.reducer'

const AuthenticatedRoutes = React.lazy(() => import('./AuthenticatedRoutes'))
const UnauthenticatedRoutes = React.lazy(() =>
  import('./UnauthenticatedRoutes')
)

export default function Routes() {
  const token = useSelector(getToken)
  const expired = token ? Date.now() >= jwt_decode(token).exp * 1000 : false

  return !token && !expired ? (
    <UnauthenticatedRoutes />
  ) : (
    <AuthenticatedRoutes />
  )
}
