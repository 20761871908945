import axios from 'axios'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import { GraphQLClient } from 'graphql-hooks'
import userSlice from 'redux/user'
import { store } from 'store'
import { getToken } from 'store/app.reducer'

const gqlAxios = axios.create()
gqlAxios.interceptors.response.use(
  function (response) {
    if (
      response?.data?.errors?.[0]?.extensions?.exception?.output?.statusCode ===
      401
    ) {
      store.dispatch(userSlice.actions.logout())
    }
    return response
  },
  function (error) {}
)

gqlAxios.interceptors.request.use(function (config) {
  const token = getToken(store.getState())

  if (token) {
    const headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }
    return {
      ...config,
      headers,
    }
  }
  return config
})

export const client = new GraphQLClient({
  url: process.env.REACT_APP_API_ORIGIN + '/graphql',
  fetch: buildAxiosFetch(gqlAxios),
})
