import styled from 'styled-components/macro'

export const HeroTitle = styled.h1`
  font-family: ${(props) =>
    props.font || "'Montserrat', Arial, Helvetica, sans-serif"};
  font-weight: ${(props) => props.weight || 800};
  font-size: 42px;
  line-height: ${(props) => props.lineHeight || '1'};
  color: ${(props) => props.color || '#fff'};
  @media screen and (min-width: 1024px) {
    font-size: ${(props) => props.size || '48px'};
  }
`
export const Title = styled.h3`
  font-family: ${(props) =>
    props.font || "'Montserrat', Arial, Helvetica, sans-serif"};
  font-weight: ${(props) => props.weight || 700};
  font-size: ${(props) => props.size || '20px'};
  line-height: ${(props) => props.lineHeight || '1.4'};
  color: ${(props) => props.color || '#000'};
`

export const Text = styled.section`
  a {
    color: #fe7e36;
    font-weight: 600;
    text-decoration: underline;
  }
`
