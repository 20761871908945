import React from 'react'
import styled, { css } from 'styled-components/macro'

import gplay from '../../assets/images/GPlay.png'
import appStore from '../../assets/images/AppStore.png'

export default function StoreLinks({ inline, text }) {
  return (
    <Footer inline={inline} text={text}>
      {text && <p>{text}</p>}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=com.smartgym.smartgym"
      >
        <AppBtn src={gplay} alt="download from google play" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://apps.apple.com/us/app/id1534700934"
      >
        <AppBtn src={appStore} alt="download from app store" />
      </a>
    </Footer>
  )
}

const Footer = styled.footer`
  margin-top: 16px;
  margin-bottom: 30px;

  p {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 14px;
  }
  a {
    display: block;
    margin-bottom: 10px;
  }
  a:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.inline &&
    css`
      display: flex;

      a {
        margin-right: 16px;
        margin-bottom: 0;
      }
    `}

  @media screen and (max-width:1023px) {
    margin-top: 40px;
  }
`

const AppBtn = styled.img`
  display: block;
  width: 140px;
  background: #000;
  border-radius: 6px;
`
