import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Hero } from './Hero'
import Container from '../atoms/Container'
import { HeroTitle, Title } from '../atoms/Typography'
import Logo from '../../assets/icons/Logo'
import Logout from '../../assets/icons/Logout'
import { Button } from '../atoms/Button'
import HeroImg from '../../assets/images/HeroImage.jpg'
import userSlice from '../../redux/user'
import Hamburger from '../atoms/Hamburger'

export default function Header({
  centered,
  title,
  subtitle,
  ButtonComp,
  loggedOut,
  inline,
  hideHamburger = false,
}) {
  const dispatch = useDispatch()

  return (
    <Hero image={HeroImg} big={centered}>
      <Nav centered={centered}>
        <LinkLogo style={{ alignSelf: 'flex-start' }} to="/">
          <LogoSvg>
            <Logo />
          </LogoSvg>
          <LogoTitle as="" font="AmpleAlt,sans-serif" weight="400">
            smart gym
          </LogoTitle>
        </LinkLogo>

        <RightNav loggedOut={loggedOut}>
          {loggedOut ? (
            <>
              <Button
                as={Link}
                to="/accedi"
                textColor="#fff"
                borderColor="#fff"
                backColor="transparent"
                ghost
              >
                login
              </Button>
              <Button as={Link} to="/iscriviti">
                iscriviti
              </Button>
            </>
          ) : (
            <>
              <LogoutLink
                to="/"
                onClick={() => dispatch(userSlice.actions.logout())}
              >
                <Logout />
                <span>Logout</span>
              </LogoutLink>

              {ButtonComp}
            </>
          )}
        </RightNav>
        {!hideHamburger && (
          <Hamburger
            onClick={() => dispatch(userSlice.actions.toggleSidebar())}
          />
        )}
      </Nav>
      <HeaderContainer
        centered={centered}
        inline={inline}
        rightNavigation={ButtonComp}
      >
        <HeroTitle>{title}</HeroTitle>
        <p>{subtitle}</p>
      </HeaderContainer>
    </Hero>
  )
}

const Nav = styled.nav`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;

  ${(props) =>
    props.centered &&
    css`
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      max-width: 1160px;
      padding: 0 20px;
      margin: 0 auto 65px;
    `}
  @media screen and (max-width:1023px) {
    padding: 20px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  @media screen and (min-width: 1024px) {
    top: 20px;
    left: 40px;
    right: 40px;
    justify-content: space-between;
    max-width: 1360px;
  }
`

const LinkLogo = styled(Link)`
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`
const LogoTitle = styled(Title)`
  color: #452562;
  @media screen and (min-width: 1024px) {
    color: #fff;
    margin-left: 8px;
  }
`
const LogoSvg = styled.div`
  @media screen and (max-width: 1023px) {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 40px;
  }
  @media screen and (min-width: 1024px) {
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    svg {
      height: 32px;
      width: 20px;
    }
  }
`

const RightNav = styled.div`
  text-align: right;
  position: relative;

  ${(props) =>
    props.loggedOut &&
    css`
      @media screen and (min-width: 1024px) {
        display: flex;

        > a:first-child {
          margin-right: 20px;
        }

        > a {
          align-self: center;
        }
      }
    `}
  @media screen and (max-width: 1023px) {
    display: none; /*TO DO: Da mettere dentro hamburger menu*/
    position: fixed;
    top: 69px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 20px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.44) 0%,
        rgba(0, 0, 0, 0.58) 100%
      ),
      linear-gradient(0deg, #9f9f9f, #9f9f9f),
      linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      linear-gradient(0deg, #000000, #000000),
      url(/static/media/HeroImage.668fb707.jpg), center;
    background-blend-mode: normal, color, overlay, saturation, normal;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    > a {
      border: none;
      background: transparent;
      text-align: left;
      font-size: 36px;
      padding: 10px 0;
      line-height: 1.2;
      &:hover {
        background: transparent !important;
        border: none !important;
      }
    }
  }
`

const HeaderContainer = styled(Container)`
  position: relative;

  ${(props) =>
    props.rightNavigation &&
    css`
      @media screen and (min-width: 1024px) {
        max-width: 1400px;
        padding-right: 230px;
      }
    `}
`

const LogoutLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  > span {
    margin-left: 6px;
  }
`
