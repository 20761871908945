import React from 'react'
import styled from 'styled-components/macro'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'graphql-hooks'
import swal from 'services/swal'
import StoreLinks from 'components/atoms/StoreLinks'
import { getSidebarOpen } from 'store/app.reducer'
import userSlice from 'redux/user'

const SIDEBAR_QUERY = `
  query Sidebar {
    myTickets(where: {valid: true}) {
      id
    }
    myActiveSubscriptions {
      expiration
    }
    myTicketsCount
  }
`

export default function Sidebar() {
  const sidebarOpen = useSelector(getSidebarOpen)
  const { data } = useQuery(SIDEBAR_QUERY)
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <SSidebar sidebarOpen={sidebarOpen}>
      <AsideNav>
        <NavLink
          onClick={() => dispatch(userSlice.actions.toggleSidebar())}
          exact
          activeClassName="active"
          isActive={(_, location) => {
            if (location.pathname === '/' || location.pathname === '/home') {
              return true
            }

            return false
          }}
          to="/"
        >
          <span>smart gym</span>
        </NavLink>
        <NavLink
          exact
          activeClassName="active"
          to="/prenota"
          onClick={(e) => {
            if (
              !data?.myActiveSubscriptions?.length &&
              data?.myTicketsCount !== 1
            ) {
              e.preventDefault()
              swal.subWarn(history)
            } else if (!data?.myTickets?.length) {
              e.preventDefault()
              swal.ticketWarn(history)
            } else {
              dispatch(userSlice.actions.toggleSidebar())
            }
          }}
        >
          <span>prenota</span>
        </NavLink>
        <NavLink
          onClick={(e) => {
            if (!data?.myActiveSubscriptions?.length) {
              e.preventDefault()
              swal.subWarn(history)
            } else {
              dispatch(userSlice.actions.toggleSidebar())
            }
          }}
          exact
          activeClassName="active"
          to="/scegli-palestra"
        >
          <span>acquista</span>
        </NavLink>
        <NavLink
          onClick={() => dispatch(userSlice.actions.toggleSidebar())}
          exact
          activeClassName="active"
          to="/come-funziona"
        >
          <span>come funziona</span>
        </NavLink>
        <NavLink
          onClick={() => dispatch(userSlice.actions.toggleSidebar())}
          exact
          activeClassName="active"
          to="/trainer"
        >
          <span>elenco personal trainer</span>
        </NavLink>
        <MobileLink as="a" href="tel:+393339329935">
          <span>contattaci</span>
        </MobileLink>
        <MobileLink
          to="/"
          onClick={() => {
            dispatch(userSlice.actions.toggleSidebar())
            dispatch(userSlice.actions.logout())
          }}
        >
          <span>logout</span>
        </MobileLink>
      </AsideNav>

      <StoreLinks />
    </SSidebar>
  )
}

const SSidebar = styled.aside`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 0 32px;

  @media screen and (max-width: 1023px) {
    display: ${(props) => (props.sidebarOpen ? 'block' : 'none')};
    position: absolute;
    top: 69px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 0 20px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.38) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(0deg, #452562, #452562),
      linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      linear-gradient(0deg, #000000, #000000),
      url(/static/media/HeroImage.668fb707.jpg), center;
    background-blend-mode: normal, color, overlay, saturation, normal;

    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;

    > footer {
      display: none;
    }
  }
`

const AsideNav = styled.nav`
  margin-top: 32px;

  > a {
    display: block;
    color: #fff;
    font-family: 'AmpleAlt', sans-serif;
    font-size: 36px;
    line-height: 1.2;
    padding: 10px 0;
    &.active {
      font-weight: bold;
    }
  }

  @media screen and (min-width: 1024px) {
    > a {
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      border-bottom: 1px solid #d8d8d8;
      margin: 0 -16px;
      color: #444444;
      font-size: 20px;
      line-height: 1.3;
      padding: 24px 16px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #e7e7e7;
      }

      &.active {
        color: #000;
        font-size: 19px;
      }

      > span {
        display: block;
        max-width: 140px;
      }
    }
  }
`

const MobileLink = styled(Link)`
  display: none !important;

  @media screen and (max-width: 1024px) {
    display: block !important;
  }
`
