import React from 'react'
import styled, { css } from 'styled-components/macro'

import SidebarRight from '../components/organisms/SidebarRight'
import Sidebar from '../components/organisms/Sidebar'
import SEO from '../components/organisms/SEO'
import LoggedInFooter from '../components/organisms/Footer/LoggedInFooter'

export default function SidebarLayout({
  HeaderComp,
  FooterComp,
  AsideComp,
  children,
  hideSidebar,
  rightSidebar,
  smallWrapper,
}) {
  return (
    <>
      <SEO />
      {HeaderComp}
      {!hideSidebar && (AsideComp || <Sidebar />)}
      <MainContent rightSidebar={rightSidebar}>
        <ContentWrapper rightSidebar={rightSidebar} smallWrapper={smallWrapper}>
          {children}
        </ContentWrapper>
        {rightSidebar && <SidebarRight />}
      </MainContent>
      {FooterComp || <LoggedInFooter />}
    </>
  )
}

const MainContent = styled.main`
  grid-area: content;

  ${(props) =>
    props.rightSidebar &&
    css`
      background-color: #f1f1f1;
      @media screen and (min-width: 1024px) {
        display: flex;
        align-items: flex-start;
      }
    `}
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;

  ${(props) =>
    props.smallWrapper &&
    css`
      max-width: 900px;
    `}
  ${(props) =>
    props.rightSidebar &&
    css`
      max-width: 810px;
      background-color: #fff;
      @media screen and (min-width: 1024px) {
        width: 65%;
      }
      @media screen and (min-width: 1280px) {
        padding-right: 90px;
      }
    `}
  @media screen and (min-width: 1024px) {
    padding: 40px;
    padding: 24px;
    padding-left: 50px;
    max-width: 1172px;
  }
`
