import * as React from 'react'

function Android(props) {
  return (
    <svg width={14} height={16} viewBox="0 0 14 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.604 1.143l.627-.935c.132-.222.394-.266.613-.133.219.133.262.4.131.62l-.534.797c1.165.634 1.85 1.732 1.934 2.974h-8.75c.083-1.229.755-2.317 1.898-2.955L4.025.696c-.131-.222-.088-.488.131-.621s.482-.089.613.133l.569.931a.578.578 0 01.01.019A5.494 5.494 0 017 .918c.587 0 1.123.08 1.604.225zM5.25 3.402c-.263 0-.438-.178-.438-.444s.175-.443.438-.443.438.177.438.443a.442.442 0 01-.438.444zm3.5 0c-.262 0-.438-.178-.438-.444s.176-.443.438-.443.438.177.438.443a.442.442 0 01-.438.444z"
        fill="#fff"
      />
      <path
        d="M2.625 5.354v6.654c0 .488.394.887.875.887h.875v2.218c0 .488.394.887.875.887s.875-.4.875-.887v-2.218h1.75v2.218c0 .488.394.887.875.887s.875-.4.875-.887v-2.218h.875c.481 0 .875-.4.875-.887V5.354h-8.75zM1.75 10.233a.884.884 0 01-.875.887.884.884 0 01-.875-.887V6.241c0-.488.394-.887.875-.887s.875.399.875.887v3.992zM14 10.233a.884.884 0 01-.875.887.884.884 0 01-.875-.887V6.241c0-.488.394-.887.875-.887s.875.399.875.887v3.992z"
        fill="#fff"
      />
    </svg>
  )
}

export default Android
