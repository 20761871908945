import styled, { css } from 'styled-components/macro'

export const Button = styled.button`
  display: block;
  font-family: 'AmpleAlt', Arial, Helvetica, sans-serif;
  color: ${(props) => props.textColor || '#fff'};
  font-size: ${(props) => props.size || '18px'};
  font-weight: 400;
  text-transform: lowercase;
  line-height: 1.4;
  padding: 8px 30px;
  border: none;
  background: linear-gradient(270deg, #fba642 0%, #fe7e36 100%);
  border-radius: 4px;
  outline: none;
  min-width: 120px;
  text-align: center;

  &[disabled] {
    color: #fff;
    opacity: 0.4;
    cursor: initial;
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: #fff;
    background: linear-gradient(270deg, #ff8e4f 0%, #fe5a36 100%);
  }

  ${(props) =>
    props.ghost &&
    css`
      background: ${(props) => props.backColor || 'transparent'};
      color: ${(props) => props.textColor || '#fe7e36'};
      border: 2px solid ${(props) => props.borderColor || '#fe7e36'};

      &:hover:not([disabled]),
      &:focus:not([disabled]) {
        color: #fff;
        background: ${(props) => props.backColor || '#FE7E36'};
        border: 2px solid #fe7e36;
      }
    `}
`
