import LoggedOutFooter from 'components/organisms/Footer/LoggedOutFooter'
import Header from 'components/organisms/Header'
import React from 'react'
import SidebarLayout from 'templates/SidebarLayout'

const Loading = () => {
  return (
    <SidebarLayout
      hideSidebar
      smallWrapper
      HeaderComp={<Header loggedOut title="Loading" subtitle="" />}
      FooterComp={<LoggedOutFooter />}
    />
  )
}

export default Loading
