import * as React from 'react'

function Logout(props) {
  return (
    <svg width={14} height={16} viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M1.556 0C.696 0 0 .716 0 1.6v12.8c0 .884.696 1.6 1.556 1.6h6.222c.86 0 1.555-.716 1.555-1.6v-4H7.778v4H1.556V1.6h6.222v4h1.555v-4c0-.884-.696-1.6-1.555-1.6H1.556zm9.333 4.8v2.4h-7v1.6h7v2.4L14 8l-3.111-3.2z"
        fill="#fff"
      />
    </svg>
  )
}

export default Logout
