import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  jwt: null,
  user: {},
  sidebarOpen: false,
  productInCheckout: null,
  favLocation: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProductInCheckout(state, { payload }) {
      return {
        ...state,
        productInCheckout: payload,
      }
    },
    toggleSidebar(state) {
      if (!state.sidebarOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'visible'
      }
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      }
    },
    setUserInfo(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    setFavLocation(state, { payload }) {
      return {
        ...state,
        favLocation: payload.favLocation,
      }
    },
    logout(state) {
      return {
        ...state,
        jwt: null,
        user: {},
      }
    },
  },
})

export default userSlice

export const getToken = (state) => state.jwt
export const getUserInfo = (state) => state.user
export const getSidebarOpen = (state) => state.sidebarOpen
export const getProductInCheckout = (state) => state.productInCheckout
export const getFavLocation = (state) => state.favLocation
