import styled, { css } from 'styled-components/macro'

// export default styled.div`
//   width: 100%;
//   max-width: 1400px;
//   margin: 0 auto;
//   padding-left: 60px;
//   padding-right: 60px;
// `

export default styled.div`
  width: 100%;
  padding: 0 24px;

  @media screen and (min-width:1024px) {
    max-width: 1126px;
    padding-left: 316px;
  }

  ${(props) =>
    props.big &&
    css`
      max-width: 1400px;
      padding-right: 40px;
    `}

  ${(props) =>
    props.centered &&
    css`
      max-width: 1160px;
      margin: 0 auto;
      padding: 0 20px;

      @media screen and (min-width: 1024px) {
        padding-left: 20px;
      }
    `}

  ${(props) =>
    props.inline &&
    css`
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    `}

  ${(props) =>
    props.alignTop &&
    css`
      align-items: flex-start;
      @media screen and (min-width: 1024px) {
        align-items: flex-start;
      }
    `}
`
