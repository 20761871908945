import styled, { css } from 'styled-components/macro'

const GreyGradient = (image) =>
  css`linear-gradient(180deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0.58) 100%), linear-gradient(0deg, #9F9F9F, #9F9F9F), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(0deg, #000000, #000000), url('${image}'), center`
const PurpleGradient = (image) => css`linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.38) 0%,
  rgba(0, 0, 0, 0.2) 100%
),
linear-gradient(0deg, #452562, #452562),
linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
linear-gradient(0deg, #000000, #000000),
url('${image}'),
center;`

export const Hero = styled.header`
  position: relative;
  grid-area: header;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 32px;
  color: #fff;
  font-size: 18px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
      props.grey ? GreyGradient(props.image) : PurpleGradient(props.image)};
    background-blend-mode: normal, color, overlay, saturation, normal;
  }

  h1 {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 1023px) {
    padding-top: 90px;
  }
  @media screen and (min-width: 1024px) {
    padding-top: ${(props) => (props.big ? '30px' : '50px')};
    padding-bottom: ${(props) => (props.big ? '330px' : '80px')};
  }
`
