import Swal from 'sweetalert2'

export default {
  warn(options) {
    Swal.fire({
      buttonsStyling: false,
      title: 'Ops!',
      icon: 'warning',
      ...options,
    })
  },
  alert(options) {
    return Swal.fire({
      buttonsStyling: false,
      title: 'Attenzione',
      ...options,
    })
  },
  error(options) {
    Swal.fire({
      buttonsStyling: false,
      title: 'Ops!',
      icon: 'error',
      ...options,
    })
  },
  input(options) {
    Swal.fire({
      icon: 'info',
      input: 'text',
      ...options,
    })
  },
  subWarn(history) {
    Swal.fire({
      buttonsStyling: false,
      title: 'Ops!',
      icon: 'warning',
      text: 'Non hai ancora sottoscritto una quota associativa',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Acquista quota',
      cancelButtonText: 'Chiudi',
      preConfirm: async () => {
        history.push('/scegli-palestra-quota')
      },
    })
  },
  ticketWarn(history) {
    Swal.fire({
      buttonsStyling: false,
      title: 'Ops!',
      icon: 'warning',
      text: 'Devi acquistare entrate per poter prenotare',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Acquista',
      cancelButtonText: 'Chiudi',
      preConfirm: async () => {
        history.push('/acquista')
      },
    })
  },
}
