import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

// TODO fornire dati
const descriptions = {}
const titles = {
  '/accedi': 'Accedi',
  '/iscrizione-effettuata': 'Iscrizione effettuata',
  '/iscriviti': 'Iscriviti',
  '/recupera-password': 'Recupera password',
  '/trainer': 'Personal trainer',
  '/home': 'Home',
  '/scegli-palestra': 'Scegli la palestra',
  '/scegli-palestra-quota': 'Scegli la palestra',
  '/prenota': 'Prenota',
  '/prenotazione-effettuata': 'Prenotazione effettuata',
  '/acquista': 'Acquista entrate',
  '/acquista-quota': 'Acquista quota di partecipazione',
  '/acquisto-effettuato': 'Acquisto effettuato',
  '/come-funziona': 'Come funziona',
  '/pagamento': 'Paga qui',
  '/cassa': 'Cassa',
}

export default function SEO({ image }) {
  const { pathname } = useLocation()
  const templatedTitle =
    pathname !== '/' ? `${titles[pathname]} | Smart Gym` : 'Smart Gym'
  const actualImage = 'https://placeimg.com/1000/350/people' || image
  const description =
    descriptions[pathname] || 'Smart gym: la tua palestra personale'

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="image" content={actualImage} />
      <meta name="description" content={description} />
      <title>{templatedTitle}</title>
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={templatedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={actualImage} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={templatedTitle} />
      <meta name="twitter:image" content={actualImage} />
      )}
    </Helmet>
  )
}
