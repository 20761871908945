import React from 'react'
import styled from 'styled-components/macro'

import FooterContent from './FooterContent'
import Container from '../../atoms/Container'

export default function LoggedOutFooter() {
  return (
    <SFooter>
      <Container centered inline alignTop>
        <FooterContent />
      </Container>
    </SFooter>
  )
}

const SFooter = styled.footer`
  position: relative;
  display: flex;
  width: 100%;
  grid-area: footer;
  background-color: #2d1047;
  padding-top: 30px;
  padding-bottom: 60px;
`
