import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import userSlice, * as fromUser from '../redux/user'

const appReducer = combineReducers({
  user: userSlice.reducer,
})

const persistConfig = {
  key: 'primary',
  storage,
  version: 0,
  /* migrate: createMigrate(migrations, { debug: env.LOGGING }), */
  whitelist: ['user'],
}
const persistedReducer = persistReducer(persistConfig, appReducer)

export default persistedReducer

export const getToken = (state) => fromUser.getToken(state.user)
export const getUserInfo = (state) => fromUser.getUserInfo(state.user)
export const getSidebarOpen = (state) => fromUser.getSidebarOpen(state.user)
export const getProductInCheckout = (state) =>
  fromUser.getProductInCheckout(state.user)
export const getFavLocation = (state) => fromUser.getFavLocation(state.user)
