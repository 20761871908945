import * as React from 'react'

function iOS(props) {
  return (
    <svg width={13} height={16} viewBox="0 0 13 16" fill="none" {...props}>
      <path
        d="M9.705 0C8.893.038 7.89.538 7.319 1.241c-.525.606-.974 1.59-.86 2.515.916.077 1.832-.463 2.405-1.147.563-.703.936-1.646.84-2.609zm-.107 4.234c-1.336 0-2.291.706-2.915.706-.634 0-1.58-.697-2.673-.697-1.336 0-2.645.813-3.335 1.997-1.418 2.367-.368 5.872 1.002 7.798C2.35 14.993 3.16 16 4.253 16c1.002-.037 1.341-.588 2.551-.588 1.219 0 1.58.588 2.673.588s1.755-.97 2.426-1.925c.766-1.082 1.079-2.138 1.097-2.193-.018-.018-2.116-.798-2.136-3.148-.018-1.963 1.654-2.9 1.73-2.954-.946-1.357-2.51-1.546-2.874-1.546h-.122z"
        fill="#fff"
      />
    </svg>
  )
}

export default iOS
