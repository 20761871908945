import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ClientContext } from 'graphql-hooks'
import Loading from 'pages/Loading'

import { store } from './store'
import Routes from './routes/Routes'
import { client } from './services/gqlClient'

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

function App() {
  return (
    <ClientContext.Provider value={client}>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        </Router>
      </Provider>
    </ClientContext.Provider>
  )
}

export default App
