import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { useQuery } from 'graphql-hooks'
import { useSelector } from 'react-redux'
import { getFavLocation, getProductInCheckout } from 'store/app.reducer'

import Card from '../atoms/Card'
import { Title, Text } from '../atoms/Typography'
import { Button } from '../atoms/Button'

const CHECKOUT_SIDEBAR_QUERY = `
  query getPackageOrPlanById($package: ID!, $plan: ID!) {
    package(id: $package) {
      description
      title
      price
      tickets
      type
    }

    subscriptionPlan(id: $plan) {
      id
      name
      description
      price
    }
  }
`

export default function SidebarRight() {
  const productInCheckout = useSelector(getProductInCheckout)
  const favLocation = useSelector(getFavLocation)
  const { data } = useQuery(CHECKOUT_SIDEBAR_QUERY, {
    // In strapi ids are mandatory
    variables: { plan: 0, package: 0, ...productInCheckout },
  })

  const pkg = data?.package
  const plan = data?.subscriptionPlan

  return (
    <SidebarCard>
      <header>
        <Title>Hai selezionato:</Title>
      </header>
      {pkg && (
        <>
          <Text>
            <p>
              <strong>{pkg.title}</strong>
              <strong>
                {pkg.tickets} entrate a {pkg.price}€
              </strong>
            </p>
          </Text>
        </>
      )}
      {plan && (
        <>
          <Text>
            <p>
              <strong>{plan.name}</strong>
              <strong>{plan.price}€</strong>
            </p>
          </Text>
        </>
      )}
      <SelectedLocation>
        <Label>Palestra</Label>
        <strong>{favLocation.name}</strong>
        <p>{favLocation.address}</p>
      </SelectedLocation>
      <footer>
        <Button
          as={Link}
          to={pkg ? '/scegli-palestra' : 'scegli-palestra-quota'}
        >
          modifica l'ordine
        </Button>
      </footer>
    </SidebarCard>
  )
}

const SidebarCard = styled(Card)`
  padding: 16px;
  margin: 16px;

  header {
    margin-bottom: 16px;
  }

  section {
    color: #626262;

    strong {
      display: block;
    }
  }

  footer {
    text-align: center;
    margin-top: 24px;
  }
  @media screen and (min-width: 1024px) {
    transform: translateY(-35px);
    margin: 0 26px;
    width: 300px;
    padding: 32px 38px 38px;
    margin-top: 0;
  }
`

const Label = styled.p`
  text-transform: uppercase;
  color: #626262;
  font-weight: 600;
  font-size: 14px;
  line-height: 4;
`

const SelectedLocation = styled.div`
  p {
    margin: 0;
  }
`
